import React, {Component} from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from 'react-router-dom';
import {createBrowserHistory} from 'history';

class App extends Component {

  state = {
    radioitems: [],
  };

  getPlayerConfiguration = (radioitem) => {
    return {
      aggregatorUrl: 'https://media-services-public-stag.vrt.be/vualto-video-aggregator-web/rest/external/v1',
      clientCode: 'radio1@STAG',
      customErrorHandling: true,
      identityToken: '',
      mediaReference: radioitem.attributes.field_asset_audio_publication_id + `$` + radioitem.attributes.field_asset_audio_id,//// //`${pub_id}` + `$` + `${audio_id}`
      theme: {
        text: 'black',
        sliderPrimary: '#0067a3',
        sliderSecondary: '#d5d6d6',
        playPause: '#0067a3',
        backForward: 'black',
        volumeMute: '#0067a3',
      }
      //poster: poster,
    };
  };

  bootPlayer = (radioitem) => {
    const renderArgs = {
      selector: 'div[data-id="vrt-audio-item-' + radioitem.attributes.drupal_internal__id + '"]',
      config: this.getPlayerConfiguration(radioitem),
      digitalData: {},
      adConfig: {},
    };
    window.VRT.audioPlayer.render(renderArgs);
  };

  componentDidMount() {

    let timeStampLast48h =  Math.floor(Date.now() / 1000) - 172800;
    let url = ('https://radio-dev.radio2.be/jsonapi/vrt_radioitem/radioitem_livestream?filter[status][value]=1&sort=-item_start&filter[last48h][condition][path]=item_start&filter[last48h][condition][operator]=%3E&filter[last48h][condition][value]=' + timeStampLast48h + '&filter[audiopubid][condition][path]=field_asset_audio_publication_id&filter[audiopubid][condition][operator]=IS%20NOT%20NULL');

    fetch(url).then(res => res.json()).then((data) => {
      this.setState({radioitems: data.data});
      let i;
      for (i = 0; i < this.state.radioitems.length; i++) {
        window.addEventListener('load', this.bootPlayer(this.state.radioitems[i]));
      }
    }).catch(console.log);
  }

  render() {
    return (
        <div>
          <img className="logo" src="https://radio2.be/themes/vrt/radio2/radio2/dist/images/radio2-logo.svg"/> <h1>Radio 2 Demo</h1>
          <div className="card">
            {this.state.radioitems.map((radioitem) => (
                <div className="card-body" key={radioitem.attributes.drupal_internal__id}>
                  {/*<a href={'/radioitem/' + radioitem.attributes.drupal_internal__id}>*/}
                  <h2 className="card-title">{radioitem.attributes.title}</h2>
                  <div className="card-description">{radioitem.attributes.field_description.value}</div>
                  <div className="card-program-title">Programma: {radioitem.attributes.field_program_title}</div>
                  {/*</a>*/}
                  <div className='vrt-audio-player-default'  id={'vrt-audio-item-' + radioitem.attributes.drupal_internal__id} data-id={'vrt-audio-item-' + radioitem.attributes.drupal_internal__id}><span>De speler is aan het laden ...</span></div>
                </div>
            ))}
          </div>
        </div>
    );
  }
}

export default App;